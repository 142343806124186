<template>
  <b-form @submit.prevent="submit" autocomplete="off">
    <b-form-group label="Nombre" label-cols-md="4" label-cols-lg="2">
      <b-form-input
        required
        type="text"
        v-model="cuadroMando.nombre"
        placeholder="Nombre del cuadro de mando"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Disposición" label-cols-md="4" label-cols-lg="2">
      <b-row>
        <b-col>
          <b-form-radio
            class="pt-2"
            v-model="cuadroMando.disposicion"
            :value="TipoDisposicion.SENCILLA"
            required
            name="disposicion"
          >
            Sencilla
          </b-form-radio>
          <b-form-radio
            class="pt-2"
            v-model="cuadroMando.disposicion"
            :value="TipoDisposicion.DOBLE_SUPERIOR"
            required
            name="disposicion"
          >
            Doble columna superior
          </b-form-radio>
          <b-form-radio
            class="pt-2"
            v-model="cuadroMando.disposicion"
            :value="TipoDisposicion.DOBLE_INFERIOR"
            required
            name="disposicion"
          >
            Doble columna inferior
          </b-form-radio>
        </b-col>
        <b-col>
          <center>
            <i>
              <span v-if="!cuadroMando.disposicion">Seleccione una disposición</span>
              <span v-else> En el dashboard se mostrará así: </span>
            </i>
          </center>
          <div class="mt-2" v-if="mostrarDisposicion(TipoDisposicion.SENCILLA)">
            <table class="table table-bordered text-center">
              <tr>
                Bloque 1
              </tr>
            </table>
          </div>
          <div class="mt-2" v-if="mostrarDisposicion(TipoDisposicion.DOBLE_SUPERIOR)">
            <table class="text-center table table-bordered">
              <tr>
                <td>Bloque 1</td>
                <td>Bloque 2</td>
              </tr>
              <tr>
                <td colspan="2">Bloque 3</td>
              </tr>
            </table>
          </div>
          <div class="mt-2" v-if="mostrarDisposicion(TipoDisposicion.DOBLE_INFERIOR)">
            <table class="text-center table table-bordered">
              <tr>
                <td colspan="2">Bloque 1</td>
              </tr>
              <tr>
                <td>Bloque 2</td>
                <td>Bloque 3</td>
              </tr>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-form-group>
    <b-form-group label="Estado" label-cols-md="4" label-cols-lg="2">
      <b-form-checkbox class="pt-2" v-model="cuadroMando.activo" switch value="1" unchecked-value="0">
        <b v-if="cuadroMando.activo == true">Activo</b>
        <b v-else>Inactivo</b>
      </b-form-checkbox>
    </b-form-group>
    <botonera-edicion url-cancelar="/administracion/cuadros-de-mando"> </botonera-edicion>
  </b-form>
</template>
<script>
import Vue from "vue";

export default {
  props: {
    cuadroMando: Object,
  },
  data() {
    return {
      indicadores: [],
      TipoDisposicion: Object.freeze({
        SENCILLA: 1,
        DOBLE_SUPERIOR: 2,
        DOBLE_INFERIOR: 3,
      }),
    };
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
    mostrarDisposicion(tipo) {
      return this.cuadroMando.disposicion == tipo;
    },
  },
};
</script>
